import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/Layout.js";
import { Link } from 'gatsby';
import SEO from '../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Wish liste" mdxType="SEO" />
    <h1>{`Ma whish list`}</h1>
    <p>{`Cette page recense mes envies et les recettes que j’aimerais réaliser`}</p>
    <h2>{`Livres`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.undejeunerdesoleil.com/2016/10/le-dessert-etait-presque-parfait-pascale-weeks-avis.html"
        }}>{`Le dessert était presque parfait`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      